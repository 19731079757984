<template>
  <div class="main-panel">
    <div class="title">
      <span>已成立泛团</span>
      <div class="d-inline-block ml-5 search">
        <el-input type="text" clearable v-model="search" placeholder="请输入ID或泛团名" style="width: 350px;"></el-input>
        <el-button type="primary" @click="searchTeam" style="margin-left: -3px;">搜索</el-button>
      </div>
    </div>
    <myTable :list="list" :name='name'>
      <template #before='scoped'>
        <div class="expand-main">
          <div class="row">
            <span>联系电话：{{ scoped.row.phone }}</span>
            <span>联系邮箱：{{ scoped.row.email }}</span>
          </div>
          <div class="row warp">
            <span>泛团简介：</span>
            <span>{{ scoped.row.profile }}</span>
          </div>
          <div class="row last-row">
            泛团备注：<el-input v-model="scoped.row.comment" size="mini" @blur="sendComment(scoped.row.teamid, scoped.row.comment)"></el-input>
          </div>
        </div>
			</template>
      <template #after='scoped'>
        <div class="btns-main">
          <el-dropdown trigger="click"  @command="editStatus(scoped.row.teamid)">
            <span class="el-dropdown-link">修改状态</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>整改</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
			</template>
    </myTable>
    <el-pagination v-if="list.length > 0" layout="prev, pager, next" :page-size="10"  :total="totalRow" @current-change="changePage">
    </el-pagination>
    <el-dialog title="拒绝理由" top="25vh" :show-close="false" :visible.sync="dialogVisible" :before-close="handleClose">
      <div class="select-main">
        <el-select size="small" v-model="reason" placeholder="请选择">
        <el-option label="因包含不当信息因此做下线处理"  value="因包含不当信息因此做下线处理"></el-option>
        <el-option label="因受到用户举报因此做下线处理"  value="因受到用户举报因此做下线处理"></el-option>
      </el-select>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" class="cancel" @click="cancel">取消</el-button>
        <el-button size="small" type="primary" class="sure" @click="sure" :disabled="!reason">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import myTable from './my-table.vue'
import { getTeamsInfo, sendChstatus } from '@/api/team-info'
export default {
  name: 'established',
  components: {
    myTable
  },
  data () {
    return {
      search: '',
      name: 'established',
      page: 1,
      totalRow: 0,
      list: [],
      reason: '',
      id: '',
      dialogVisible: false
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      const params = {
        search: this.search,
        page: this.page,
        pageSize: 10,
        status: 0
      }
      const res = await getTeamsInfo(params)
      if (res.code === 0) {
        this.totalRow = res.data.pager.totalRow
        this.list = res.data.teams || []
      } else {
        this.$notify({
          title: '失败',
          type: 'error',
          message: res.message,
          duration: 2000
        })
      }
    },
    searchTeam () {
      this.page = 1
      this.getList()
    },
    changePage (p) {
      this.page = p
      this.getList()
    },
    sendComment (id, text) {
      // if (!text) return
      const data = {
        teamid: id,
        comment: text
      }
      sendChstatus(data).then(res => {
        if (res.code === 0) {
          this.$notify({
            title: '成功',
            message: res.message,
            type: 'success',
            duration: 1000
          })
        } else {
          this.$notify({
            title: '失败',
            type: 'error',
            message: res.message,
            duration: 1000
          })
        }
      })
    },
    editStatus (id) {
      this.dialogVisible = true
      this.id = id
    },
    cancel () {
      this.dialogVisible = false
      this.reason = ''
    },
    sure () {
      const data = {
        teamid: this.id,
        status: 3,
        comment: this.reason
      }
      sendChstatus(data).then(res => {
        if (res.code === 0) {
          this.reason = ''
          this.dialogVisible = false
          if (this.list.length === 1) {
            this.page = this.page - 1
          }
          this.getList()
          this.$notify({
            title: '成功',
            message: res.message,
            type: 'success',
            duration: 1000
          })
        } else {
          this.$notify({
            title: '失败',
            type: 'error',
            message: res.message,
            duration: 1000
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.main-panel{
  .title{
    font-size: 20px;
    line-height: 20px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 36px;
  }
  .el-pagination{
    margin-top: 190px;
  }
  .expand-main{
    overflow: hidden;
    padding: 0 1px;
    .last-row{
      padding-right: 50px;
      /deep/.el-input__inner{
        border-radius: 6px !important;
        border: 1px solid #BBBBBB;
      }
    }
    .row{
      display: flex;
      margin: 0;
      margin-bottom: 10px;
      /deep/.el-input{
        flex: 1;
        .el-input__inner{
          border-radius: 0px
        }
        .el-input__inner:focus{
          border: 1px solid #707070;
        }
      }
      span{
        flex: 1;
      }
    }
    .warp{
      width: 100%;
      display: flex;
      span:first-child{
        flex: 1;
        max-width: 70px;
        min-width: 70px;
      }
      span:st-child{
        flex: 9;
        width: 100%;
      }
    }
  }
}
.btns-main{
  .el-dropdown-link{
    display: inline-block;
    width: 84px;
    height: 34px;
    line-height: 34px;
    background-color: #F56C6C;
    color: #FFFFFF;
    font-size: 14px;
    cursor: pointer;
    border-radius: 8px;
  }
}
</style>
<style lang="less">
.el-dropdown-menu{
  overflow: hidden;
  margin-top: 0px !important;
  transform: translateX(-15px);
  height: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  li{
    height: 50px;
    line-height: 50px;
  }
}
.el-dialog{
  width: 340px;
  height: 286px;
  border-radius: 15px;
  padding: 0 15px;
  .el-dialog__header{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }
  .el-dialog__body{
    padding-top: 15px;
  }
  .select-main{
    height: 100px;
    .el-select{
      width: 100%;
    }
  }
  .dialog-footer{
    display: flex;
    justify-content: center;
    .el-button{
      width: 76px;
      border: 2px;
      margin: 0 18px;
      outline: 0;
    }
    .cancel{
      color: #3775F6;
      border: 1px solid #3775F6;
      &:hover{
        background-color: #fff;
      }
    }
    .sure{
      background-color: #3775F6;
      border: 1px solid #3775F6;
      &:disabled{
        opacity: 0.7;
      }
    }
  }
}
</style>
